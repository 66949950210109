@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

/* noinspection CssInvalidAtRule */
@tailwind base;
/* noinspection CssInvalidAtRule */
@tailwind components;

@layer components {
    /* Hack for checkmark colors */
    [type="checkbox"]:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%237F4491' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    }

    [type="radio"]:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%237F4491' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    }

    [type="checkbox"],
    [type="radio"] {
        @apply focus:ring-0 focus:ring-offset-0;
    }

    [type="checkbox"],
    [type="checkbox"]:checked,
    [type="checkbox"]:checked:hover,
    [type="checkbox"]:checked:focus,
    [type="checkbox"]:indeterminate:hover,
    [type="radio"],
    [type="radio"]:checked,
    [type="radio"]:checked:hover,
    [type="radio"]:checked:focus {
        @apply border-purple-700;
    }
}

/* noinspection CssInvalidAtRule */
@tailwind utilities;